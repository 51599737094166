@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "variables";
@import "mixin";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $poppins;
  overflow-x: hidden;
  background: $body_bg;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all .5s ease;

  &.active {
    width: 100%;
    height: 100%;
    display: block;

    @include res(700) {
      display: none;
    }
  }
}

section {
  min-height: 100vh;

  a.btn {
    outline: none;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    color: white;
    background: linear-gradient(93.43deg, #FFDD69 0%, #FFB13C 104.86%);
    border-radius: 35px;
    padding: 21px 48px;
    transition: background 1000ms ease-out;

    &:hover {
      background: linear-gradient(93.43deg, #FF5F3C 0%, #FF9E44 104.86%);
      box-shadow: 0px 20px 30px -5px rgba(74, 30, 20, 0.25);
      transition: background 1000ms ease-in;
    }

    @include res(600) {
      padding: 16px 31px;
      font-size: 18px;
    }
  }
}

.hamburger-menu {
  position: fixed;
  top: 28px;
  right: 75px;
  background: $white;
  box-shadow: 0 15px 30px -5px rgba(78, 78, 78, 0.25);
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all .5s ease;
  z-index: 99999;
  padding: 18px 0;
  border-radius: 50%;

  @include res(600) {
    top: 10px;
    right: 15px;
  }

  .bar {
    width: 30px;
    height: 4px;
    border-radius: 3px;
    position: relative;
    transform: translateY(25px);
    transition: all 0ms 300ms;

    &:after, &:before {
      width: 30px;
      height: 4px;
      border-radius: 3px;
      background: $grey;
    }

    &.animate {
      background: rgba(255, 255, 255, 0);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 25px;
      transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      margin-bottom: -7px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      margin-top: -5px;
      width: 20px;
      margin-left: 10px;
    }

    &.animate {
      &:after {
        top: -15px;
        transform: rotate(45deg);
        transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
        margin-top: 0px;
        width: 30px;
        margin-left: 0px;
      }

      &:before {
        bottom: 15px;
        transform: rotate(-45deg);
        transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
        margin-bottom: 0;
      }
    }
  }

  &:hover {
    cursor: pointer;
    transition: all 0ms 300ms;

    .bar {

      &:before, &:after {
        background: $blue;
      }
    }

    .bar:not(.animate) {

      &:before, &:after {
        background: $blue;
      }

      &:after {
        width: 30px;
        margin-left: 0;
      }

      &:before {
        width: 20px;
        margin-left: 10px;
      }
    }
  }
}

.show {
  opacity: 1;
  width: 100%;
  z-index: 9999;
}

.hide {
  opacity: 0;
  width: 0;
  z-index: -1;
}

.mobile-nav {
  background: $white;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 645px;
  min-height: 100vh;
  height: 100%;
  text-align: center;
  transition: opacity .5s ease;
  -moz-transition: opacity .5s ease;
  -webkit-transition: opacity .5s ease;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @include res(700) {
    max-width: 100%;
    padding-top: 50px;
  }

  a {
    font-weight: bold;
    font-size: 54px;
    line-height: 66px;
    text-transform: uppercase;
    color: $grey;
    margin: 0 75px 48px 70px;
    text-decoration: none;
    text-align: right;
    position: relative;

    @include res(950) {
      margin: 0 60px 38px;
      font-size: 40px;
      line-height: 46px;
      text-align: left;
    }

    @include res(600) {
      margin: 0 30px 28px;
      font-size: 26px;
      line-height: 32px;
    }

    @include res(540) {
      font-size: 18px;
      line-height: 22px;
    }

    &:first-child {
      padding-top: 180px;

      @include res(1200) {
        padding-top: 140px;
      }

      @include res(950) {
        padding-top: 80px;
      }
    }

    &:hover, &.active {
      color: $blue;

      &:before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        height: 6px;
        width: 100px;
        border-radius: 3px;
        border-bottom: 6px solid $light_blue;

        @include res(950) {
          width: 50px;
          border-bottom: 3px solid $light_blue;
          left: 0;
        }

        @include res(600) {
          width: 30px;
          border-bottom: 2px solid $light_blue;
        }
      }
    }

    &.small {
      font-size: 24px;
      line-height: 36px;

      @include res(950) {
        font-size: 22px;
        line-height: 30px;
      }

      @include res(600) {
        font-size: 18px;
        line-height: 22px;
      }

      @include res(540) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.title {
  background-image: url("../images/title_circle_1.png");
  background-repeat: no-repeat;
  position: absolute;
  z-index: 99;
  background-position: bottom 50px right 29px;
  top: -120px;

  h2 {
    font-weight: 800;
    font-size: 72px;
    line-height: 84px;
    color: $blue;
    padding: 205px 0 138px;
    text-transform: uppercase;

    @include res(900) {
      font-size: 62px;
      line-height: 63px;
      padding: 221px 0 138px;
    }

    @include res(700) {
      font-size: 50px;
      line-height: 55px;
      padding: 0;
    }

    @include res(600) {
      font-size: 40px;
      line-height: 46px;
    }

    @include res(450) {
      font-size: 32px;
      line-height: 40px;
    }

    @include res(390) {
    font-size: 24px;
    }
    }

  @include res(700) {
    background: none;
    position: relative;
    top: 0;
  }
}

#home {
  background-image: url("../images/top_pic.jpg");
  background-repeat: no-repeat;
  width: -webkit-fill-available;
  background-position: right top;
  background-size: contain;

  @include res(1780) {
    background-size: 50%;
  }

  .text-block {
    max-width: 100%;
    color: $font-color;
    padding: 40px 75px 100px;
    background-image: url("../images/left_side_rect.png");
    background-repeat: no-repeat;
    background-position: left top 234px;
    margin-bottom: 100px;

    @include res(1200) {
      max-width: 100%;
      padding: 32px 55px 80px 55px;
    }

    @include res(700) {
      padding: 22px 30px 40px 30px;
      margin-bottom: 0px;
    }

    @include res(600) {
      padding: 22px 15px 40px 15px;
      background-image: none;
    }

    .logo {
      margin-bottom: 222px;

      @include res(540) {
        margin-bottom: 122px;
      }
    }

    @include res(820) {
      br {
        display: none;
      }
    }

    h1 {
      font-weight: 800;
      font-size: 72px;
      line-height: 84px;
      text-transform: uppercase;
      color: $blue;

      @include res(1200) {
        font-size: 66px;
        line-height: 72px;
      }

      @include res(998) {
        font-size: 55px;
        line-height: 62px;
      }

      @include res(600) {
        font-size: 40px;
        line-height: 46px;
      }

      @include res(400) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    h2 {
      margin: 96px 0 64px;
      font-weight: 300;
      font-size: 28px;
      line-height: 42px;
      text-align: inherit;
      letter-spacing: normal;
      text-transform: inherit;
      color: $font-color;

      @include res(1200) {
        margin-top: 30px;
      }

      @include res(600) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

#ourServices {
  padding: 50px 75px 90px;
  position: relative;
  background-image: url("../images/buble1.png");
  background-repeat: no-repeat;
  background-position: top 120px right;

  @include res(700) {
    padding: 75px 30px 90px;
  }

  @include res(600) {
    padding: 75px 15px 60px 15px;
  }

  .servises {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 259px;

    @include res(700) {
      margin-top: 10px;
    }

    .item {
      max-width: 32%;
      background: $white;
      box-shadow: 0px 5px 10px rgba(78, 78, 78, 0.05);
      border-radius: 25px;
      width: 100%;
      text-align: center;
      padding: 48px 10px 64px;
      margin-top: 30px;
      height: 274px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include res(1024) {
        max-width: 48%;
        padding: 35px 10px 40px;
        height: auto;
      }

      @include res(780) {
        max-width: 100%;
        max-height: 100%;
      }

      @include res(540) {
        padding: 28px 10px 49px;
      }

      img {
        width: fit-content;
        margin-bottom: 32px;
        transition: all 0.5s ease-out;

        @include res(1024) {
          transform: scale(0.8);
        }

        @include res(980) {
          margin-bottom: 20px;
        }

      }

      h3 {
        color: $font-color;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;

        @include res(540) {
          font-size: 16px;
        }
      }

      p {
        display: none;
        font-size: 18px;
        line-height: 27px;
        color: $font-color;
        transition: all 0.5s ease-out;

        @include res(1024) {
          display: block;
          font-size: 16px;
          margin-top: 5px;
          line-height: 24px;
        }

        @include res(540) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      &:first-child {
        img {
          margin-top: 26px;

          @include res(540) {
            margin-top: 18px;
          }
        }
      }

      @media screen and (min-width: 1024px) {
        &:hover {
          cursor: pointer;

          @include res(1780) {
            padding: 30px 10px;

            h3 {
              margin-top: -5px;
              font-size: 20px;
              line-height: 22px;
            }
          }

          @include res(1340) {
            padding: 0 10px;
          }

          @include res(1024) {
            padding: 5px 10px;
          }

          p {
            display: block;

            @include res(1780) {
              font-size: 16px;
              margin-top: 5px;
              line-height: 24px;
            }

            @include res(1024) {
              margin-bottom: 15px;
            }
          }

          img {
            transform: scale(0.5);
            margin-bottom: 0;

            @include res(1024) {
              margin-top: 0;
            }
          }

          &:first-child {
            img {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

#aboutUs {
  padding: 20px 75px;
  position: relative;

  @include res(1024) {
    padding: 20px 75px 134px;
  }

  @include res(700) {
    padding: 75px 30px 134px;
  }

  @include res(600) {
    padding: 75px 15px 50px 15px;
  }

  .title {
    background-image: url("../images/title_circle_2.png");
    background-position: bottom 0px right 29px;

    @include res(700) {
      background: none;
    }
  }

  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    padding: 32px;
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0px 15px 30px -5px rgba(17, 87, 79, 0.1);
    backdrop-filter: blur(25px);
    margin-bottom: 30px;
    border-radius: 25px;

    @include res(540) {
      font-size: 14px;
      line-height: 22px;
      padding: 15px;
      margin-bottom: 20px;
    }

    @include res(700) {
      br {
        display: none;
      }
    }

    span {
      font-weight: 500;
    }
  }

  img {
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0px 15px 30px -5px rgba(17, 87, 79, 0.1);
    backdrop-filter: blur(25px);
    padding: 32px;
    border-radius: 25px;

    @include res(700) {
      padding: 5px;
    }
  }

  .row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 266px;

    @include res(1024) {
      flex-wrap: wrap;
    }

    @include res(700) {
      margin-top: 40px;
    }

    .col {
      max-width: 48%;

      @include res(1024) {
        max-width: 100%;
      }
    }
  }
}

.bg {
  background-image: url("../images/middle_pic.jpg");
  background-repeat: no-repeat;
  background-position: center;
}

#clientsReviews {
  padding: 40px 75px 180px;
  position: relative;
  background-image: url("../images/circles_bottom.png");
  background-repeat: no-repeat;
  background-position: top right;

  @include res(1024) {
    background-image: none;
  }

  @include res(700) {
    padding: 70px 30px 80px;
  }

  @include res(600) {
    padding: 70px 15px 40px 15px;
  }

  .reviews {
    margin-top: 250px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    @include res(1024) {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    @include res(700) {
      margin-top: 40px;
    }

    .item {
      max-width: 32%;
      background: $white;
      box-shadow: 0px 5px 10px rgba(78, 78, 78, 0.05);
      border-radius: 25px;
      padding: 32px;
      height: fit-content;
      margin-bottom: 20px;

      @include res(1024) {
        max-width: 46%;
      }

      @include res(850) {
        max-width: 100%;
        padding: 15px;
      }

      .user-info {
        display: flex;
        margin-bottom: 22px;

        @include res(600) {
          margin-bottom: 10px;
        }

        div {
          align-self: center;
          margin-left: 24px;
        }

        h3 {
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: $font-color;

          @include res(540) {
            font-size: 16px;
            line-height: 22px;
          }
        }

        h4 {
          font-style: italic;
          font-weight: 500;
          font-size: 18px;
          color: $grey;
          line-height: 27px;

          @include res(540) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: $font-color;

        @include res(540) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

#getInTouch {
  margin-top: 40px;
  position: relative;
  padding: 60px 0px 0 75px;
  background-image: url("../images/bottom_pic.jpg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;

  @include res(1780) {
    background-size: 65%;
  }
  @include res(1200) {
    background-size: 78%;
  }

  @include res(1024) {
    padding-bottom: 400px;
    background-size: 103%;
  }

  @include res(700) {
    padding: 40px 0px 0 30px;
    background: none;
  }

  @include res(600) {
    padding: 30px 15px 20px 15px;
  }

  h3 {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: $font-color;
    margin: 290px auto 64px;

    @include res(970) {

      br {
        display: none;
      }
    }

    @include res(700) {
      margin: 60px auto 64px;
      font-size: 22px;
      line-height: 30px;
    }

    @include res(540) {
      margin: 60px auto 64px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .white-block {
    background: rgba(255, 255, 255, 0.35);
    backdrop-filter: blur(25px);
    border-radius: 40px 0px 0px 0px;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 32px 48px 8px;
    max-width: 654px;

    @include res(700) {
      background: $violet;
      border-radius: 0;
      padding: 22px 30px 0px;
    }

    @include res(600) {
      padding: 15px 15px 5px;

    }

    .links {
      display: flex;
      margin-bottom: 30px;
      text-transform: capitalize;

      @include res(540) {
        margin-bottom: 15px;
      }

      a {
        font-weight: bold;
        font-size: 16px;
        line-height: 33px;
        color: $white;
        text-decoration: none;
        position: relative;

        &:last-child {
          margin-left: 48px;

          @include res(540) {
            margin-left: 22px;
          }
        }

        @include res(540) {
          font-size: 14px;
          line-height: 22px;
        }

        &:hover {
          &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 6px;
            width: 106px;
            border-radius: 3px;
            border-bottom: 6px solid $light_blue;

            @include res(1024) {
              content: none;
            }
          }
        }
      }
    }

    p {
      font-weight: 300;
      font-size: 11px;
      line-height: 16px;
      color: $white;
      margin-bottom: 24px;

      @include res(540) {
        margin-bottom: 10px;
      }
    }
  }
}

#terms, #LegalMentions {
  padding: 42px 75px 50px;
  flex-direction: column;

  @include res(1200) {
    padding: 32px 55px;
  }

  @include res(600) {
    padding: 22px 20px;
  }

  @include res(700) {
    padding: 42px 30px 50px;
  }

  @include res(600) {
    padding: 32px 15px 40px 15px;
  }

  .header_content {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 64px;

    @include res(600) {
      margin-bottom: 20px;

      img {
        max-width: 20px;
      }
    }

    a {
      align-self: center;
      padding-top: 7px;
      margin-right: 20px;

      @include res(600) {
        padding-top: 0;
      }
    }
  }

  h2 {
    font-size: 72px;
    line-height: 85px;
    text-transform: uppercase;
    color: $blue;
    text-align: initial;
    letter-spacing: normal;

    @include res(1200) {
      font-size: 51px;
      line-height: 61px;
    }

    @include res(600) {
      font-size: 36px;
      line-height: 40px;
    }

    @include res(540) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  p {
    font-size: 21px;
    line-height: 28px;
    text-align: justify;
    color: $font-color;
    margin-bottom: 38px;

    span {
      margin-top: 40px;
      font-weight: 700;
    }

    @include res(1200) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 26px;
    }

    @include res(600) {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 16px;
    }
  }
}
