// Fonts
$poppins: 'Poppins', sans-serif;

// Colors
$font-color: #404040;
$yellow: #FFDD15;
$black: #000000;
$grey: #BFBFBF;
$white: #ffffff;
$blue: #3237BE;
$light_blue: #5FCAC4;
$violet: #7678b5;
$body_bg: #FDFDFD;
